html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
  #__next {
    height: 100%;
  }
}

body:lang(en) {
  .main-menu {
    @media (min-width: 767px) {
      font-size: 11px;
    }

    @media (min-width: 1040px) {
      font-size: 13px;
    }

    @media (min-width: 1100px) {
      font-size: 14px;
    }

    @media (min-width: 1140px) {
      font-size: 15px;
    }
  }
}

.ant-menu-root {
  height: 100%;
}

.entry-content {
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    margin-bottom: 0.6rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  strong,
  b {
    font-weight: 500;
  }

  ol,
  ul {
    margin-bottom: 0.6rem;
    list-style-type: disc;
    padding-left: 35px;

    li {
      margin: 0.6rem 0;
    }

    ul,
    ol {
      margin: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol {
    list-style-type: decimal;
  }

  a:not(.btn),
  a:not(.elementor-button) {
    color: #f26522;
  }

  blockquote {
    padding: 2rem;
    font-size: 1.25rem;
    font-weight: normal;
    background-color: #fafafa;
    margin: 1rem 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    table,
    ul,
    ol {
      font-size: inherit;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  hr {
    margin: 1rem 0;
    border-color: #c6c6c6;
  }

  iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
    margin: 0 auto;

    @media (min-width: 767px) {
      width: auto;
      margin: 0 auto;
    }
  }
}

.ant-table-thead {
  tr > {
    > th {
      font-weight: 600 !important;
      background: #f3f3f3 !important;
    }
  }
}

.ant-table-tbody {
  > tr.ant-table-row {
    &:hover {
      > td {
        background: #fff5ee !important;
      }
    }
  }
}

.input-filter .ant-input-group-addon {
  padding: 0 !important;
}

.row-dragging {
  background: #fff5ec;
  border: 1px solid #f3b985;
}

.ant-modal-content {
  height: 100%;
}

::selection {
  color: initial;
  background: #ffe3c6;
}

.ant-image-preview-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-name-label label {
  display: flex !important;
}

.youtubeContainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.youtubeContainer iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.equal-height-JlocK {
  display: block;
  overflow: hidden;
  transition-property: height;
}

.ant-table-expanded-row .ant-table {
  margin: 0 !important;
}

.ant-table-expanded-row .ant-table-thead {
  display: none;
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  padding: 0 !important;
}

tr.ant-table-row.product-deleting td {
  background-color: #ffecec !important;
}

.tox-promotion,
.tox-statusbar__branding {
  display: none !important;
}

.swiper-pagination-bullet {
  background: #6f4200 !important;

  &.swiper-pagination-bullet-active {
    background: #f5bb7c !important;
  }
}

.category-menu-dropdown {
  position: absolute;
  top: 100%;
  left: -55px;
  z-index: 9999;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 1000px;
  display: none;
}

.category-menu-item {
  position: relative;
  &:hover {
    .category-menu-dropdown {
      display: block;
    }
  }
}

.custom-product-archive-pagination {
  > li {
    margin: 0 !important;
    border: 1px solid #f26522 !important;
    border-right: 0 !important;
    border-radius: 0 !important;

    &:last-child {
      border-right: 1px solid #f26522 !important;
    }

    a {
      &:hover {
        color: #f26522 !important;
        background: #fff3ed !important;
      }
    }

    .ant-pagination-item-link {
      color: #292929 !important;
      border-radius: 0 !important;
      font-size: 15px !important;

      .anticon {
        vertical-align: -0.1em;
      }

      &:hover {
        color: #f26522 !important;
        background: #fff3ed !important;
      }
    }

    &.ant-pagination-item-active {
      a {
        color: #fff !important;
        background: #f26522 !important;
      }
    }
  }
}

button.add-to-cart-button[disabled] {
  opacity: 1 !important;
  background: #9e9e9e !important;
  color: #fafafa !important;
}

// **
// * Swiper
.swiper-slider {
  overflow: hidden;
}

.app-breadcrumb {
  ol {
    flex-wrap: wrap;
  }
}

.ant-message {
  z-index: 99999;
  font-family: 'Kanit', sans-serif;
}

.ant-notification-notice-message {
  font-weight: 500;
}

// **
// * Checkbox
.checkbox-white-bg {
  .chakra-checkbox__control {
    background-color: #fff;
    border-width: 1px;

    &[data-checked] {
      background-color: #f26522;
    }
  }
}

// **
// * Cart
.cart,
.checkout,
div[class^='checkout-received'],
.checkout-payment {
  @media (max-width: 767px) {
    .layout-footer-warpper {
      display: none;
    }
  }
}

div[class^='products-'] {
  @media (max-width: 767px) {
    .layout-footer-warpper {
      display: none;
    }
  }
}

.cart,
.checkout,
div[class^='checkout-received'],
.checkout-payment,
div[class^='products-'] {
  .footer-mobile-nav {
    @media (min-width: 992px) {
      display: none;
    }
  }
}

.dealer-shop-swiper {
  .swiper-pagination {
    bottom: -10px !important;
  }
}

.contact-us {
  white-space: nowrap;
}
